import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import { data } from 'jquery'
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import Accordion from '../accordion';

const TermHolder = ({data}) => {
  return (

    <div type={data.type} >

        {data.featureType === 'Two Column' &&
            <div class="twoColumnCallout [alignment] [type] [class(es)]">
                <div class="copy">
                    <span class="step">[stepNumber]</span>
                    <h3>{data.title}</h3>
                    {data.copy != null &&
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    }
                    <div class="logoCollage">
                        [logo(s)]  
                    </div>
                    {
                        data?.buttons && data.buttons.map((buttons, key) => <div>
                            <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                        </div>)
                    }
                </div>
                <div class="graphic">
                    {data.graphic != null &&
                        <img src={data.graphic.file.url} alt="" />
                    }
                </div>
            </div>
        }

        {data.featureType === 'FAQ' &&
            <div class="FAQ">
                <div class="copy">
                    <span class="step">[stepNumber]</span>
                    <h3>{data.title}</h3>
                    {data.copy != null &&
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    }
                    <div class="logoCollage">
                        [logo(s)]  
                    </div>
                    {
                        data?.buttons && data.buttons.map((buttons, key) => <div>
                            <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                        </div>)
                    }
                </div>
                <div class="graphic">
                    {data.graphic != null &&
                        <img src={data.graphic.file.url} alt="" />
                    }
                </div>
            </div>
        }

        {data.type === "Features" && 
            <div className='featureList'>
                <div className="features">
                    {data.terms.map((term, key) =>
                        <div key={key} className="feature">
                            {term.graphic != null &&
                                <img src={term.graphic.file.url} alt="" />
                            }
                            <h3>{term.title}</h3>
                            <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                        </div>
                    )}
                </div>
            </div>
        }

        {data.type === "Reports" && 
            <div className='reportList'>
                <div className="reports">
                    {data.terms.map((term, key) =>
                        <div key={key} className="report">
                            {term.graphic != null &&
                                <img src={term.graphic.file.url} alt="" />
                            }
                            <div className="copy">
                                <h4>{term.title}</h4>
                                <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        }

        {data.type === "Benefits" && 
            <div className='benefitListContainer'>
                <div className="benefitsList">
                    <h2>{data.title}</h2>
                    {data.terms.map((term, key) =>
                        <div key={key} className="benefit">
                            <div className="copy">
                                <h4>{term.title}</h4>
                                <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                            </div>
                        </div>
                    )}
                </div>
                <Helmet
                    htmlAttributes={{
                        // lang,
                    }}
                    title='Benefits | Oversight'
                    titleTemplate={`Benefits | Oversight`}
                />
            </div>
        }

        {data.type === 'Integrations' && 
            <div className={`integrationList ${data?.customClasses}`}>
                <h2>{data.title}</h2>
                <div className="integrations">
                {data.terms.map((term, key) => 
                        <div className="integration">
                            <div className="title">
                                { term.graphic != null && <img src={term.graphic.file.url} alt={term.title} /> }
                                <h4>{term.title}</h4>
                            </div>
                            <div className="copy">
                                <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                            </div>
                            { term?.destination &&
                                <Link className='learnMore' to={`/${term.destination}`}>Learn More</Link>
                            }
                        </div>
                )}
                </div>
            </div>
        }

        {data.type === "Problems Solved" && 
            <div className='problemsSolvedHolder'>
                <div className="problemSolved">
                    <div className="header">
                        <h3>{data.title}</h3>
                        <div className="copy">
                            {data.copy != null &&
                                <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                            }
                        </div>
                    </div>
                    <div className="problems">
                        {data.terms.map((term, key) =>
                            <div key={key} className="problem">
                                <h4>{term.title}</h4>
                                <div className="problemCopy">
                                    <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                                </div>
                                {
                                    data?.buttons && data.buttons.map((buttons, key) => <div>
                                        <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                                    </div>)
                                }
                                <div className="solutionCopy">
                                    <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        }

        {data.type === "FAQ" && 
            <div className='FAQholder'>
                <div className="FAQ">
                    <div className="header">
                        <h3>{data.title}</h3>
                        <div className="copy">
                            {data.copy != null &&
                                <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                            }
                        </div>
                    </div>
                    <div className="faqs">
                        {data.terms.map((term, index) => (
                            <Accordion title={term.title} content={documentToReactComponents(JSON.parse(term.definition.definition))} />
                        ))}
                    </div>
                </div>
            </div>
        }

        {data.type === "Use Cases" &&
            <div className="useCases">
                
                <div className="subheading">Use Cases</div>
                <div className="header">
                    <h3>{ data.title }</h3>
                    <div className="copy">
                        {data.copy != null &&
                            <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                        }
                    </div>
                </div>
                {data.terms != null &&
                <ol className="">
                    { data.terms.map((term, key) => 
                        <li className="">
                            <h6>{ term.title }</h6>
                            <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                        </li>
                    )}
                </ol>
                }
                <Helmet
                    htmlAttributes={{
                        // lang,
                    }}
                    title='Solutions | Oversight'
                    titleTemplate={`Solutions | Oversight`}
                />
            </div>
        }

        {data.type === "Terms List" &&
            <div className="serviceCallout ContentfulServiceCallout">
                <div className="roles">
                    <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    <ul className='termList'>
                        { data.terms.map((term, key) => 
                            <li className="term">
                                <h5>{ term.title }</h5>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        }
        
        {data.type === "Feature Benefits" &&
            <div className={`centeredCallout ${data?.customClasses}`}>
                <h2>{data.title}</h2>
                <ul className='benLists'>
                    { data.terms.map((term, key) => 
                        <li className="benList">
                            { term.graphic != null && <img src={term.graphic.file.url} alt={term.title} /> }
                            <h5>{ term.title }</h5>
                            <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                        </li>
                    )}
                </ul>
                {
                    data?.buttons && data.buttons.map((buttons, key) => <div>
                        <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                    </div>)
                }
            </div>
        }

        {data.featureType === 'Ribbon Callout' &&
            <div class="centeredCallout [type] [class(es)]">
            {/* <div class="centeredCallout [type] [class(es)]" style="background-image: url(BACKGROUNDIMAGE); background-color: BACKGROUNDCOLOR;"> */}
                <h2>{data.title}</h2>
                <div class="copy">
                    {data.copy != null &&
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    }
                </div>
                {
                    data?.buttons && data.buttons.map((buttons, key) => <div>
                        <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                    </div>)
                }
            </div>
        }

        {data.featureType === 'Boxed Callout' &&
            <div class="centeredCallout [type] [class(es)]">
                <div className="boxBackground">
                    <h2>{data.title}</h2>
                    <div class="copy">
                        {data.copy != null &&
                            <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                        }
                    </div>
                    <div className="btnContainer">
                        {
                            data?.buttons && data.buttons.map((buttons, key) =>
                            <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                            )
                        }
                    </div>
                </div>
            </div>
        }

    </div>
  )
}

export default TermHolder