import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';


const videoList = ({data}) => {
  return (
    <div className='videoList '>
      <h3>{data.title}</h3>
      <p>{documentToReactComponents(JSON.parse(data.content.content))}</p>
      {data.screenshot!==null &&
       <img src={data.screenshot.file.url} width={800} alt="" />
      }
      <br/>
      {data.caption!==null &&
        <small>{data.caption.caption}</small>
      }

      <Helmet
          htmlAttributes={{
            // lang,
          }}
          title='How To | Oversight'
          titleTemplate={`Steps | Oversight`}
        />
    </div>
  )
}

export default videoList