import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from '../components/contentTypes/pageBuilder'

const pageTemplate = (props) => {

  const data = props.pageContext.data
    return (
      // <Layout pageInfo={{ pageName: data.slug }} navigation={props.pageContext.navigation} footer={props.pageContext.footer}>
      <Layout pageInfo={{ pageName: data.slug }} >
        <SEO 
          title={data.seoTitle} 
          description={data.seoDescription?.seoDescription}
          image={data.seoThumbnail?.file.url}
        />
        <PageBuilder data={data.pagebuilder} />
      </Layout>
    )
}

export default pageTemplate
