import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet'
import $ from "jquery";

const Feature = ({data, lang}) => {

    useEffect( () => {
        $('.flipper span:first').addClass('is-visible');

        $(".copyP ol li").click(function(){
            if($(this).find("p").html()=="Automated Reports")
                $("html,body").animate({scrollTop:$(".featureOne").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            if($(this).find("p").html()=="Agile Resource Planning")
                $("html,body").animate({scrollTop:$(".featureThree").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            if($(this).find("p").html()=="Interactive Dashboards")
                $("html,body").animate({scrollTop:$(".featureTwo").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            if($(this).find("p").html()=="Adaptive Budget Management")
                $("html,body").animate({scrollTop:$(".featureFour").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            if($(this).find("p").html()=="Smart Forecasting & Notifications")
                $("html,body").animate({scrollTop:$(".featureFive").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            if($(this).find("p").html()=="Data-Rich Project Hubs")
                $("html,body").animate({scrollTop:$(".featureSix").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            if($(this).find("p").html()=="Custom Reports & Dashboards")
                $("html,body").animate({scrollTop:$(".featureSeven").offset().top- $("html,body").offset().top+ $("html,body").scrollTop(),scrollLeft:0},300);
            
        });
        var animationDelay = 2500;
      
      animateHeadline($('.flipper'));
      
      function animateHeadline($headlines) {
            $headlines.each(function(){
                var headline = $(this);
                //trigger animation
                setTimeout(function(){ hideWord( headline.find('.is-visible') ) }, animationDelay);
                //other checks here ...
            });
          }
          function hideWord($word) {
            var nextWord = takeNext($word);
            switchWord($word, nextWord);
            setTimeout(function(){ hideWord(nextWord) }, animationDelay);
          }
      
          function takeNext($word) {
            return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
          }
      
          function switchWord($oldWord, $newWord) {
            $oldWord.removeClass('is-visible').addClass('is-hidden');
            $newWord.removeClass('is-hidden').addClass('is-visible');
          }
      })

      

  return (

    <div type={data.type} >

        {data.featureType === 'Two Column' && 
            <div id={`${data?.anchor}`} className={`twoColumnCallout ${data?.copyAlignment} ${data?.customClasses ? data?.customClasses.reduce((accumulator, currentValue) => accumulator + ' ' + currentValue) : ''}`}>
                <div className="background" style={ data?.backgroundImage ? { backgroundImage : `url(${data?.backgroundImage.file.url})`} : {}}></div>
                <div class="graphic">
                    {data.graphic != null &&
                        <img src={data.graphic.file.url} alt="" />
                    }
                </div>
                <div class="copy">
                    {data.stepNumber != null && <span class="step">0{data.stepNumber}</span> }
                    {data.symbol != null &&
                        <div class="symbol">
                                <img src={data.symbol.file.url} alt="" />
                        </div>
                    }
                    <h3>{data.title}</h3>
                    {data.copy != null &&
                        <p className='copyP'>
                            {documentToReactComponents(JSON.parse(data.copy.copy))}
                        </p>
                    }
                    {data.logos != null &&
                        <div class="logoCollage">
                            {data.logos.map((logo, key) => 
                                <img key={key} src={logo.file.url} alt="" />
                            )}
                        </div>
                    }
                    <div className="btnContainer">
                        {
                            data?.buttons && data.buttons.map((buttons, key) => <div>
                                <Link to={buttons.destination} title={buttons.title} className={`button ${buttons.type} ${buttons?.customClasses ? buttons?.customClasses.reduce((accumulator, currentValue) => accumulator + ' ' + currentValue) : ''}`}>{buttons.title}</Link>
                            </div>)
                        }
                    </div>
                    {data.highlight != null &&
                        <div class="highlight">{data.highlight}</div>
                    }
                    
                </div>
            </div>
        }

        {data.title === 'Interactive Dashboards' && 
            <div className="featureTwo">
                <div class="copy">
                    {data.stepNumber != null && <span class="step">0{data.stepNumber}</span> }
                    <h3>{data.title}</h3>
                    {data.copy != null &&
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    }
                </div>
                {data.termsHolder != null &&
                    <div className='dashboard'>
                        <ul>
                            {data.termsHolder.terms.map((term, key) => 
                                <li key={key}>
                                    <img src={term?.graphic?.file.url} alt="" />
                                    <h4>{term.title}</h4>
                                    <p>{documentToReactComponents(JSON.parse(term.definition.definition))}</p>
                                </li>
                            )}
                        </ul>
                    </div>
                }
                <div className="copy">
                        {
                            data?.buttons && data.buttons.map((buttons, key) => <div>
                                <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                            </div>)
                        }
                    </div>
                <Helmet
                    htmlAttributes={{
                        // lang,
                    }}
                    title='Feaures | Oversight'
                    titleTemplate={`Features | Oversight`}
                />
            </div>
        }

        {data.title === 'We are small platform, growing fast! If you don’t see an integration you need, let us know.' && 
            <div className="stripeCallout">
                <div class="copy">
                    <h5>{data.title}</h5>
                </div>
                <div className="cta">
                    {
                        data?.buttons && data.buttons.map((buttons, key) => <div>
                            <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                        </div>)
                    }
                </div>
                <Helmet
                    htmlAttributes={{
                        // lang,
                    }}
                    title='Integrations | Oversight'
                    titleTemplate={`Integrations | Oversight`}
                />
            </div>
        }
        
        {data.featureType === 'Flipper Hero' &&
            <div className={`hero 
                    ${data?.customCss ? data?.customCss.reduce((accumulator, currentValue) => accumulator + ' ' + currentValue) : ''}
                `}
                >
                <div className="background" style={ data?.backgroundImage ? { backgroundImage : `url(${data?.backgroundImage.file.url})`} : {}}></div>
                    <div className="copy">
                    <h1 class="flipHeadline">{ data.title }&nbsp;
                        
                    </h1>
                    <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    </div>
                    { data.graphic != null && <img src={data.graphic.file.url} alt={data.title} /> }
                    <Helmet
                        htmlAttributes={{
                        lang,
                        }}
                        title='Oversight | Ditch the Spreadsheets'
                        titleTemplate={`Oversight | Ditch the Spreadsheets`}
                    />
                    
            </div>
        }

        {data.featureType === 'Ribbon Callout' &&
            <div class={`centeredCallout ${data?.customClasses}`}>
                {data.backgroundImage != null && <div className="background" style={ { backgroundImage : `url(${data?.backgroundImage.file.url})`} }></div> }
                {data.symbol != null &&
                        <div class="symbol">
                                <img src={data.symbol.file.url} alt="" />
                        </div>
                }
                <h2>{data.title}</h2>
                <div class="copy">
                    {data.copy != null &&
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    }
                </div>
                {
                    data?.buttons && data.buttons.map((buttons, key) => <div>
                        <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                    </div>)
                }
            </div>
        }

        {data.featureType === 'Boxed Callout' &&
            <div class={`centeredCallout ${data?.customClasses}`}>
                <div className="boxBackground">
                    <h2>{data.title}</h2>
                    <div class="copy">
                        {data.copy != null &&
                            <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                        }
                    </div>
                    <div className="btnContainer">
                        {
                            data?.buttons && data.buttons.map((buttons, key) =>
                            <Link to={buttons.destination} title={buttons.title} class={`button ${buttons.type}`}>{buttons.title}</Link>
                            )
                        }
                    </div>
                </div>
            </div>
        }

        {data.featureType === 'Centered' &&
            <div class="solutionsHero">
                <div className="background"></div>
                <h2>{data.title}</h2>
                <div class="copy">
                    {data.copy != null &&
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    }
                </div>
            </div>
        }

        <div className="problemSolutionCallout">

            {['The Problem: Project Management Software', 'The Problem: Calendars ', 'The Problem: Time Tracking Software', 'The Problem: Time Tracking Software', 'The Problem: CRMs ', 'The Problem: Calendars', 'The Problem: Payment Processors', 'The Problem: Payment Processors ', 'The Problem: CRMs', 'The Problem: Design Tools', 'The Problem: Collaboration Tools', 'The Problem: Collaboration Tools / Slack', 'The Problem: Code Management'].includes(data.title) &&
                <div className={`twoColumnCallout ${data?.customClasses}`}>
                    <div className="inner">
                        <h3>{data.title}</h3>
                        <h4>{data.subtitle}</h4>
                        <div class="copy">
                            <p className='copyP'>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                        </div>
                    </div>
                    <div class="graphic">
                        {data.graphic != null &&
                            <img src={data.graphic.file.url} alt="" />
                        }
                    </div>
                </div>
            }
            
            {data.featureType === 'Text Callout' &&
                <div className={`solution  ${data?.customClasses}`}>
                    <div className="inner">
                        <h3>{data.title}</h3>
                        <h4>{data.subtitle}</h4>
                        <div className="copy">
                            <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                        </div>
                        {data.termsHolder != null &&
                            <div className="solutionList">
                                
                                <div className="tableHead">
                                    {data.termsHolder != null && <p className="tableP">{data.termsHolder.leftTitle}</p> }
                                    {data.termsHolder.leftList != null &&
                                    <ul>
                                        {data.termsHolder.leftList.map((list, key) =>
                                            <li>{list}</li>
                                            )}
                                    </ul>
                                    }
                                </div>
                                <div className="tableHead">
                                {data.termsHolder != null && <p className="tableP">{data.termsHolder.rightTitle}</p> }
                                    {data.termsHolder.rightList != null &&
                                    <ul>
                                        {data.termsHolder.rightList.map((list, key) =>
                                            <li>{list}</li>
                                            )}
                                    </ul>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>

        {data.title === 'What Is Oversight?' &&
            <div className="listCallout">
                <h3>{data.title}</h3>
                <div className="copy">
                    <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                </div>
                <h4>{data.termsHolder.title}</h4>
                {data.termsHolder.terms != null &&
                    <ul className='termList'>
                        {data.termsHolder.terms.map((term, key) => 
                            <li className='term'>
                                <img src={term.graphic.file.url} alt="" />
                                <h5>{term.title}</h5>
                            </li>
                        )}
                    </ul>
                }
            </div>
        }

        {['Why Jira?', 'Why Everhour?', 'Why Toggl?', 'Why Harvest?', 'Why Outlook? ', 'Why Stripe? ', 'Why QuickBooks?', 'Why Capsule?', 'Why HubSpot?', 'Why Figma? ', 'Why Github?', 'Why Bitbucket?', 'Why Teams?', 'Why Slack? ', 'Why Trello?', 'Why Teamwork? ', 'Why Google Calendar?'].includes(data.title) &&
            <div className="integrationCallout">
                <div className="copy">
                    <div className="richtext">
                        <h4>{data.title}</h4>
                        <h5>{data.subtitle}</h5>
                        <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                    </div>
                    <div className="graphic">
                       {data.graphic != null && <img src={data.graphic.file.url} alt="" /> }
                    </div>
                </div>
                {data.listCallout != null &&
                    <ul className='ibList'>
                        {data.listCallout.map((list, key) =>
                            <li>{list}</li>
                        )}
                    </ul>
                }
            </div>
        }

    </div>
  );
};

export default Feature