import React, { Fragment } from "react"
import ContactForm from "../callouts/contactForm"
import VideoOrder from "../videoOrder"
// import BlogPost from '../callouts/blogPost'
import Story from '../callouts/story'
import About from '../callouts/about'
import Feature from '../callouts/feature'
import TermHolder from "../callouts/termHolder"
import FeatureHowTo from "../featureHowTo"
import JumpNav from "../jumpNav"
import Pricing from "../pricing"

const pageBuilder = props => {
    let builds = '';
    let howtos=[];
    let currentPageRender="";
    if(props.data) {
      builds = props.data.map((data, key) => {
        switch (data.__typename) {
          case 'ContentfulContactForm':
            return (<ContactForm data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulStory':
            return (<Story data={data} key={data.__typename + '_' + key} />);
          // case 'ContentfulBlogPost':
          //   return (<BlogPost data={data} key={data.__typename + '_' + key} index={key}/>)
          case 'ContentfulHowTo':
            currentPageRender='ContentfulHowTo';
            howtos.push(data);  
          case 'ContentfulFeature':
            return (<Feature data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulAbout':
            return (<About data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulTermHolder':
            return (<TermHolder data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulVideoList':
            return (<FeatureHowTo data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulJumpNav':
            return (<JumpNav data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulPricing':
            return (<Pricing data={data} key={data.__typename + '_' + key} />);
          default:
          return null;
        }
      });
      
      if(howtos.length>0){
        builds=[];
        builds.push(<VideoOrder data={howtos} key='how-to' />)
      }
    }
    return <Fragment>{ builds && builds }</Fragment>
}

export default pageBuilder