import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className="question" onClick={() => setIsActive(!isActive)}>
                                <h4>{title}</h4>
                                <div className={isActive ? "hide" : "answer"}>
                                    <p>{content}</p>
                                </div>
    </div>
  );
};

export default Accordion;