import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import RenderRichText from "../renderRichText";

const ContactForm = ({data}) => {
    return (
        <div className={`contactForm ${ data.__typename }`} id="contact-us">
            <div className="contained">
                <div className="copy">
                    <h3>{ data.title }</h3>
                    <p>{documentToReactComponents(JSON.parse(data.copy.copy))}</p>
                </div>
                <form action="https://getoversight.us7.list-manage.com/subscribe/post?u=249ae98259202c89531b7aaac&amp;id=45e0862b82" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div class="information">
                        {/* <label htmlFor="FNAME">Full Name</label> 
                        <input type="text" id="fullName" name="FNAME" placeholder="Your full name"/> */}
                        <label htmlFor="EMAIL">Email</label>
                        <div className="emailCol">
                            <input type="email" id="email" name="EMAIL" placeholder="Your email"/>
                            <input type="submit" value="Receive Updates" className="button outline light"/>
                        </div>
                    </div>
                    <div class="yourMessage">
                        {/* <label htmlFor="MMERGE6">Your Message</label>
                        <textarea id="yourMessage" name="MMERGE6" placeholder="Write your message here"></textarea>
                        <input type="submit" value="Send us a message" className="button outline light"/> */}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactForm