import React from 'react'
import VideoList from '../videoList';
import ScrollIntoView from 'react-scroll-into-view'

const Step = ({data,closeDetails}) => {

  return (
    <div className={`steps`}>
      <div className='crumb'>
        <a href='/'>Home</a>
        <div className="aBreak">/</div>
        <a onClick={()=>closeDetails()}>How To Videos</a>
        <div className="aBreak">/</div>
        <a className='detailTitle'>{data.title}</a>
      </div>
      <div className="contents">
        <div className="sidebarTab">
          <p>Table of contents</p>
          <div className='sidebarLineTop'></div>
          { data.steps.map((item, key) => <>
            <span><ScrollIntoView selector={`#Step${key}`}><a>{(key==0)?`Introduction`:`Step ${key}`}</a></ScrollIntoView> </span>
          <div className='sidebarLine'></div>
          </>)}
        </div>
        <div className="HowToStep">
          <div className="header">
            <h5>{ data.title }</h5>
          </div>
          <iframe className='video' width={827} height={464} src={data.videoUrl} ></iframe>
          <ul className='tags'>{data.tags.map(data=><li>{data}</li>)}</ul>
          <ol className="howSteps">
          { data.steps.map((item, key) => <div  id={`Step${key}`}><VideoList data={item} key={key} /></div>)}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Step