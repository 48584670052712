import React, { useState,useEffect } from "react";

export default function VideoOrder({ data }) {

  return (
    <div className='featureVideoList'>
        <h3>{data.title}</h3>
        <div className="listOrder">
            {data.videos.map((howtos) => {
                return (
                
                    <div className="videos ">
                        <iframe
                        className="videoItem"
                        src={howtos.videoUrl}
                        width={440}
                        height={240}
                        ></iframe>
                        <ul className="tags">
                        {howtos.tags.map((tag) => (
                            <li>{tag}</li>
                        ))}
                        </ul>
                        <h4 className="titleName">
                            {howtos.title}
                        </h4>
                    </div>
                
                );
            })}
        </div>
    </div>
  );
}
