import React from "react";
import RenderRichText from "./renderRichText";

const JumpNav = ({data}) => {
    return (
        <div className="jumpNav">
            <div class="content"><RenderRichText data={data.copy.json} /></div>
                <ul>
                            {data.navItems.map((term, key) => 
                                <li key={key}>
                                    <a href={term.destination}><img src={term?.graphic?.file.url} alt="" /><h4>{term.title}</h4></a>
                                </li>
                            )}
                </ul>
            
        </div>
    )
}

export default JumpNav