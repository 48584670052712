import React, { useState,useEffect } from "react";
import Step from "./callouts/step";
import HowTo from "./callouts/howTo";

export default function VideoOrder({ data }) {
  const [showDetails, setShowDetails] = useState(false);
  const [stepData, setStepData] = useState(false);
  const [filteredList,setFilteredList]=useState([])

  const closeDetails = () => {
    setShowDetails(false);
  };
  useEffect(()=>{
    setFilteredList(data);
  },[])

  const filterDataList=(filter)=>{
    if(filter===""){
      setFilteredList(data);
    }else{
      console.log("filter",filter)
      let newList=data.filter(function(item){
        console.log(item.tags,typeof(item.tags));
        return item.tags.includes(filter);
      });
      console.log(newList);
      setFilteredList(newList);
    }
  }

  const filterBySearchText=(search)=>{
    let newList=data.filter(function(item){
      if(item.title.toLocaleLowerCase().includes(search))
      return item;
    });
    setFilteredList(newList);
  }

  const [visible, setVisible] = useState(9);

    const showMoreItems = () => {
      setVisible((prevValue) => prevValue + 3);
    }

  return filteredList != undefined ? (
    <>
      {showDetails == true ? (
        <Step data={stepData} closeDetails={closeDetails} />
      ) : (
        <div className="">
           <div className="">
              <HowTo filterDataList={filterDataList} filterBySearchText={filterBySearchText}/>
          </div>
          <div className='videoOrder'>
            {filteredList.slice(0, visible).map((howtos) => {
                return (
                
                    <div className="videos ">
                        <iframe
                        className="videoItem"
                        src={howtos.videoUrl}
                        width={440}
                        height={240}
                        ></iframe>
                        <ul className="tags">
                        {howtos.tags.map((tag) => (
                            <li>{tag}</li>
                        ))}
                        </ul>
                        <h4 className="titleName">
                        <a
                            onClick={() => {
                            setStepData(howtos);
                            setShowDetails(true);
                            }}
                        >
                            {howtos.title}
                        </a>
                        </h4>
                    </div>
                
                );
            })}
          </div>
            {/* <div className="tabButton">
                <button className='buttonTab' onClick={showMoreItems}>View all how to videos</button>
            </div> */}
        </div>
      )}
    </>
  ) : (
    <div></div>
  );
}
