import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'

const about = ({data}) => {
  return (
    <div className=' ourStoryThanks ContentfulCenteredCallout'>
      <div className="background"></div>
      <h2>{data.title}</h2>
      <blockquote>
      <p>{ data.quote.quote }</p>
      <p>{data.cite}</p>
      <p><a href={data.citeLink.destination} className='tragicLinked'>{data.citeRole}</a></p>
      </blockquote>
      {documentToReactComponents(JSON.parse(data.copy.copy))}
      <div className="ctaBtnContainer">
        {
            data?.buttons && data.buttons.map((buttons, key) => <div>
                <a href={buttons.destination} title={buttons.title} class={`button ${buttons.type} `}>{buttons.title}</a>
            </div>)
        }
      </div>
    </div>
  )
}

export default about