import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React, { useEffect, useState } from "react";
import RenderRichText from "./renderRichText";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import $ from "jquery";

const Pricing = ({data}) => {
      let cost = 2;
      const [checked, setChecked] = useState(false);
      const [radioValue, setRadioValue] = useState('1');
      const radios = [
        { name: 'Yearly', value: '1' },
        { name: 'Monthly', value: '2' },
      ];
      const handleRadioChange = (e) => {
        setRadioValue(e.currentTarget.value);
        if (e.currentTarget.value == 2) {
          setTotal(total * 2);
        }
        else {
          setTotal(total / 2);
        }
      }

      const [checkedState, setCheckedState] = useState(
        new Array(data.included.length).fill(false)
      );
      checkedState[0] = true;
      const [total, setTotal] = useState(2);
      const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
    
        setCheckedState(updatedCheckedState);
        console.log(updatedCheckedState);
    
        const totalPrice = updatedCheckedState.reduce(
          (sum, currentState, index) => {
            if (currentState === true) {
              return  sum + data.included[index].cost * radioValue;
            }
            return sum;
          },
          0
        );
    
        setTotal(totalPrice);
        var totalCost = totalPrice;
      };
    return (
        <div class="pricingWrap"><p class="topNotice">Time Tracking is <strong>FREE</strong> until June 2023 - if you sign up before Jan 1, 2023.</p>
        <div className="pricing">

            <h3 className="subtext">{data.title}</h3>
            <h2>{data.subtitle}</h2>
            <div className="content"><RenderRichText data={data.copy.json} /></div>
            <ButtonGroup>
                {radios.map((radio, idx) => (
                <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'yearly' : 'monthly'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={handleRadioChange}
                >
                    {radio.name}
                </ToggleButton>
                ))}
            </ButtonGroup>
            <div className="bundle">
            <div class="labelRow">{data.labels.map((term, key) => 
                        <span className="label">{term.title}</span>
                            )}</div>
                <h4>$<span className="price">{total}</span>/mo</h4>
                <h5>per resource</h5>
                <div class="modules">
                    <label htmlFor="custom-checkbox">
                        <input
                            type="checkbox"
                            id="custom-checkbox"
                            name="projectBudget"
                            value="2"
                            checked="checked"
                            className="hiddenCheck nopointer" readOnly={true}/> <span class="checkmark"></span>
                        Projects + Budgets
                    </label>
                    <label htmlFor="custom-checkboxone">
                        <input
                            type="checkbox"
                            id="custom-checkboxone"
                            name="projectBudget"
                            value="2"
                            checked="checked"
                            className="hiddenCheck nopointer" readOnly={true}/> <span class="checkmark"></span>
                        Time Tracking
                    </label>
                {data.included.map((term, index) => 
                    <label htmlFor={`custom-checkbox-${index}`} key={index}>
                        <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={term.title}
                            value={term.cost}
                            checked={checkedState[index]}
                            onChange={() => handleOnChange(index)}
                            className="hiddenCheck"
                        /> <span class="checkmark"></span>
                        {term.title}
                        {term.definition != null &&
                        <span className="definition">{documentToReactComponents(JSON.parse(term.definition.definition))}</span>
                        }
                    </label>

                            )}</div>
                    <div className="buttonRow">
                    <a href={data.button.destination} title={data.button.title} class={`button ${data.button.type}`}>{data.button.title}</a>
                    </div>
            </div>
        </div></div>
    )
}

export default Pricing