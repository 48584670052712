import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
// import TopImg from '../../images/rectangleDark.png'
import Benihana from '../../images/benihana.png'
import Github from '../../images/github.png'
import Cash from '../../images/burncash.jpg'
import { Helmet } from "react-helmet";

const story = ({ data }) => {
  return (
    
    <div className="backgroundStory" >

      {data.title === "The Tragic Truth" &&

        <div class="twoColumnCallout story tragicTruth showVideo">
          <div className="copy">
            <h3>{data.title}</h3>
            {documentToReactComponents(JSON.parse(data.copy.copy))}
          </div>
          <div className="graphic">
            {data.videoUrl != null &&
                <iframe
                width="560"
                height="315"
                src={data.videoUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                ></iframe>
            }
          </div>
          <Helmet
              htmlAttributes={{
                  // lang,
              }}
              title='Our Story | Oversight'
              titleTemplate={`Our Story | Oversight`}
          />
        </div>
      }

      {data.title === "Over the course" &&

        <div className="ourStoryTimeline chevron ContentfulCenteredCallout">
          <div className="background"></div>
          <h2>{data.title}</h2>
          {documentToReactComponents(JSON.parse(data.copy.copy))}
          <ol>
            {data.list.map((list, key) => (
              <li key={key}>
               <p>{list}</p>
              </li>
            ))}
          </ol>
          <p>{data.childContentfulStorySubtitleTextNode.subtitle}</p>
        </div>
      }

      {data.title === "If at First you Don't Succeed..." &&
        <div class="twoColumnCallout storyThree [alignment] [type]">
          <div className="columns">
            
            <div class="copy ">
              <h3 className="storyTitle">{data.title}</h3>
              {data.copy != null && (
                <div className="storyCopy">
                  {documentToReactComponents(JSON.parse(data.copy.copy))}
                </div>
              )}
            </div>
            <div className="imgRow">
              <img src={Benihana} alt="" />
              <img src={Github} alt="" />
              <img src={Cash} alt="" />
            </div>
            <div className="bottomSub">
              {data?.childContentfulStorySubtitleTextNode && <h5>{data.childContentfulStorySubtitleTextNode.subtitle}</h5> }
            </div>

          </div>
        </div>
      }

    </div>
  );
};

export default story;
