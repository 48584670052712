import React ,{useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import { Helmet } from "react-helmet";
const HowTo = (props) => {
  const [filter,setFilter]=useState("");

  return (
    <div className={`howTo`}>
      <div className="HowToVideos">
        <div className="videoHeader">
          <h3 className="videoTitle">How To Videos</h3>
          <div className="videoTabs">
            <span className={(filter=="latest")?"active-tab":""}
            onClick={()=>{props.filterDataList('');setFilter("latest")}}>Latest Videos</span>
            <span className={(filter=="projects")?"active-tab":""}
            onClick={()=>{props.filterDataList('project');setFilter("projects")}}>Projects</span>
            <span className={(filter=="planning")?"active-tab":""}
            onClick={()=>{props.filterDataList('planning');setFilter("planning")}}>Planning</span>
            <span className={(filter=="report")?"active-tab":""}
            onClick={()=>{props.filterDataList('reports');setFilter("report")}}>Reports</span>
            <span className={(filter=="integrations")?"active-tab":""}
            onClick={()=>{props.filterDataList('integrations');setFilter("integrations")}}>Integrations</span>
          </div>
          <Dropdown className="dropdownMenu">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Filter
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('')}>Latest Videos</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('project')}>Projects</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('planning')}>Planning</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('reports')}>Reports</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('integrations')}>Integrations</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="tabLine"></div>
        <div className="searchVideos">
          <input
            className="seachInput"
            type="text"
            onChange={(e)=>{props.filterBySearchText(e.target.value.toLocaleLowerCase())}}
            placeholder="How can we help you?"
          />
        </div>
        <div className="videoOrder">
        </div>
        
      </div>
      <Helmet
          htmlAttributes={{
            // lang,
          }}
          title='How To | Oversight'
          titleTemplate={`How To | Oversight`}
        />
    </div>
  );
};

export default HowTo;
